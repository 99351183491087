<template>
  <div class="content container">
    <h1>Listen to a Podcast and Shop!</h1>

    <div class="row">
      <div class="col-md-6">
        <!-- MEDIA -->
        <template v-if="media.length">
          <div
            v-for="med in media"
            :key="med.id"
            class="med my-4"
          >
            <b-card
              class="overflow-hidden"
              no-body
            >
              <b-card-text class="ml-3 mt-3">
                <div class="d-flex">
                  <b-button
                    v-if="playing_id !== med.id"
                    class="btn btn-primary"
                    @click.prevent="playPodcast(med)"
                  >
                    Play
                  </b-button>
                  <b-button
                    v-if="playing_id === med.id"
                    class="btn btn-primary"
                    @click.prevent="stopPodcast()"
                  >
                    Stop
                  </b-button>
                  <div class="d-flex ml-3">
                    <b-img
                      :src="med.created_by.profile_picture"
                      rounded="circle"
                      width="50"
                      height="50"
                      alt
                    />
                    <div class="ml-4">
                      <p class="h5 mb-0">
                        {{ med.title }}
                      </p>
                      <small>uploaded by coach
                        <a href="">
                          {{ med.created_by.first_name }} {{ med.created_by.last_name }}
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </b-card-text>
              <b-card-text
                v-if="med.description !== ''"
                class="mx-3"
              >
                {{ med.description }}
              </b-card-text>
            </b-card>
          </div>
        </template>

        <!-- NO MEDIA -->
        <div v-if="loaded && !media.length && !errors">
          No media yet.
        </div>

        <!-- LOADING -->
        <b-spinner
          v-if="loading"
          variant="info"
          style="width: 80px; height: 80px;"
        />

        <!-- ERROR -->
        <div v-if="errors">
          <b-alert
            show
            variant="danger"
          >
            Unable to load media. Please test your connection and refresh the page.
          </b-alert>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="my-3 text-center">
          SHOP COMING SOON
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      loading: false,
      loaded: false,
      errors: false,
      media: [],
      last_week: '',
      playing_id: 0,
      audio: null,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.fetchMedia();
  },
  methods: {
    async fetchMedia() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}podcasts/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;
      this.loaded = true;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.media = responseJson;
      } else {
        this.errors = true;
      }
    },
    async playPodcast(media) {
      if (this.audio) {
        this.stopPodcast();
      }
      if (media) {
        const audio = new Audio(media.file);
        audio.preload = 'metadata';
        audio.play();
        this.audio = audio;
        this.playing_id = media.id;
      }
    },
    stopPodcast() {
      this.audio.pause();
      this.playing_id = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio {
  margin: 0 auto;
  max-width: 500px;

  iframe {
    margin-top: -10px;
    width: 100%;
    height: 300px;
  }
}
</style>
